.head{
    width: 100%;
    display: flex;
    top: 0;
    position:fixed;
    align-items: center;
   
background-color: rgb(21, 20, 20);}


.logo{
    left: 0;
    flex-direction: row;
    display: flex;
margin-left: -830px;
    height:40px;
width: 150px;
}
.avatar{
    
color:chartreuse;
    margin-left: 800px;
    height: 40px;
    width: 50px;
}
.input{
    background-color:rgb(19, 221, 221);
width: 100px;
    margin-left:400px;
    justify-items: center;
    height: 10px;
padding-left: 20px;
border: none;
outline: none;
border-radius: 20px;
}
.top{

    top: 0;
}
.input:hover{
background-color:yellowgreen;
    color: black;
}
.head:hover{

    cursor: pointer;
}
.avatar:hover{
transform: scale(1.1);
    color: red;
    
}
body{color:aqua;}
.btn{

    border-radius: 20px;
}
.btn:hover{
background-color: rgb(80, 255, 53);
    cursor: pointer;
}
.nav_black{
    background-color: black;
}


body{


    overflow-x: hidden;
background-color:black;

    color: aqua;
}
.small{
border-radius: 20px;
    height: 140px;
    width: 200px;
}